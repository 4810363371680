.FlexTiles {
  background: var(--primary-300, #fff6f9);
  padding: clamp(16px, calc(32vw / 7.68), 32px) 0;
  margin: calc(clamp(92px, calc(114vw / 7.68), 114px) * -1) 0 clamp(39px, calc(83vw / 7.68), 148px);
  @media (min-width: 1050px) {
    margin-top: -82px;
  }
  header {
    max-width: calc(587rem / 16);
    margin: 0 auto clamp(24px, calc(36vw / 7.68), 48px);
    text-align: center;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    $gap: clamp(16px, calc(24vw / 7.68), 32px);
    gap: $gap;
    .item {
      text-align: center;
      padding: clamp(12px, calc(16vw / 7.68), 24px) clamp(12px, calc(16vw / 7.68), 28px);
      border-radius: 20px;
      border: 1px solid var(--primary-500, #ffb8ce);
      width: calc(100% / 3 - $gap * (1 - 1 / 3));
      img {
        width: clamp(80px, calc(140vw / 7.68), 140px);
      }
      h3 {
        margin: clamp(12px, calc(20vw / 7.68), 20px) 0 clamp(8px, calc(12vw / 7.68), 12px);
      }
      .paragraph {
        margin-bottom: clamp(20px, calc(24vw / 7.68), 24px);
      }
      @media (max-width: 1099px) {
        width: calc(100% / 2 - $gap * (1 - 1 / 2));
      }
      @media (max-width: 699px) {
        width: 100%;
      }
    }
  }
}

.WaveTop,
.WaveBottom {
  position: absolute;
  width: 100vw;
  left: calc(var(--pageMargin) * -1);
  @media (min-width: 1280px) {
    left: calc((100vw - 1200px) * -0.5);
  }
}
.WaveTop {
  bottom: calc(100% - 1px);
  height: clamp(45px, calc(96vw / 7.68), 171px);
}
.WaveBottom {
  top: calc(100% - 1px);
  height: clamp(39px, calc(83vw / 7.68), 148px);
}
